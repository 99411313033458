import { useMediaQuery } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Loader from "../../../assets/loader.gif";
import { theme } from "../../../utils/styles";
interface CustomLoaderProps {
  isLoading: boolean | any;
}
const CustomLoader = (props: CustomLoaderProps) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 101,
        backgroundColor: "rgb(0 0 0 / 80%) !important",
      }}
      open={props.isLoading!}
    >
      {/* <CircularProgress color="inherit" /> */}
      {isDesktop ? (
        <img
          src={Loader}
          style={{ borderRadius: "50%", width: "100px" }}
          alt="Not Found..."
        />
      ) : (
        <img
          src={Loader}
          style={{ borderRadius: "50%", width: "70px" }}
          alt="Not Found..."
        />
      )}
    </Backdrop>
  );
};

export default CustomLoader;
